import {createFeatureSelector, createSelector} from "@ngrx/store";
import {userFeatureKey, UserState} from "../reducers/user.reducer";
import {AppState} from "../reducers";
import {UserModel} from "../../../models/interfaces/user-model";
import {DictionaryModel} from "../../../models/interfaces/dictionary-model";
import {NewsModel} from "../../../models/interfaces/news-model";
import {CalendarEventModel} from "../../../models/interfaces/events-model";
import {NotificationModel} from "../../../models/interfaces/notification-model";

const selectUserState = createFeatureSelector<UserState>(userFeatureKey);

export const selectUser = createSelector<AppState, UserState, UserModel | null>(selectUserState, ({user}) => user);

export const selectLoggedOut = createSelector<AppState, UserState, boolean>(
    selectUserState,
    ({isLoggedOut}) => isLoggedOut,
)

export const selectDictionary = createSelector<AppState, UserState, DictionaryModel | null>(selectUserState, ({dictionary}) => dictionary);

export const selectNotification = createSelector<AppState, UserState, NotificationModel[]>(selectUserState, ({notifications}) => notifications);

export const selectIsLoading = createSelector<AppState, UserState, boolean>(selectUserState, ({isLoading}) => isLoading);

export const selectNews = createSelector<AppState, UserState, NewsModel[] | null>(selectUserState, ({ newsList }) => newsList);

export const selectSidebarCalendarEvents = createSelector<AppState, UserState, CalendarEventModel[] | null>(selectUserState, ({ sidebarCalendarEventsList }) => sidebarCalendarEventsList);
