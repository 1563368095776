import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {AppState} from "../reducers";
import {
    selectDictionary,
    selectIsLoading,
    selectLoggedOut,
    selectNews,
    selectNotification,
    selectSidebarCalendarEvents,
    selectUser
} from "../selectors/user.selectors";
import {UserActions} from "../actions";
import {NotificationModel} from "../../../models/interfaces/notification-model";
import {Observable} from "rxjs";
import {CalendarEventModel} from "../../../models/interfaces/events-model";

@Injectable({providedIn: "root"})
export class UserFacade {

    readonly user$ = this.store.select(selectUser);

    readonly setLoggedOut$ = this.store.select(selectLoggedOut);

    readonly dictionary$ = this.store.select(selectDictionary);

    readonly notifications$ = this.store.select(selectNotification);

    readonly isLoading$ = this.store.select(selectIsLoading);

    // This information might appear to belong to the store of a specific module, but it is essential at the root level of the application.
    // `newsList` holds information about new messages and is used for the new message indicator.
    // `sidebarCalendarEventsList` contains data used in the sidebar calendar widget.

    readonly newsList$ = this.store.select(selectNews);

    readonly sidebarCalendarEventsList$: Observable<CalendarEventModel[] | null>  = this.store.select(selectSidebarCalendarEvents);

    constructor(
        private readonly store: Store<AppState>
    ) {
    }

    refreshUser(): void{
        this.store.dispatch(UserActions.refreshUser());
    }

    setNotification(notification: NotificationModel): void {
        this.store.dispatch(UserActions.setNotification({notification}));
    }

    removeNotification(notification: NotificationModel): void {
        this.store.dispatch(UserActions.removeNotification({notification}));
    }

    setLoading(isLoading: boolean): void {
        this.store.dispatch(UserActions.setLoading({isLoading}));
    }

    setLoggedOut(): void {
        this.store.dispatch(UserActions.setLoggedOut());
    }

    initNews(): void {
        this.store.dispatch(UserActions.initNews());
    }

    loadSidebarCalendarEventsDataChunk(dateFrom: Date, dateTo: Date): void {
        this.store.dispatch(UserActions.loadSidebarCalendarEventsDataChunk({ dateFrom: dateFrom, dateTo: dateTo }));
    }

}
