import {createAction, props} from "@ngrx/store";
import {UserModel} from "../../../models/interfaces/user-model";
import {DictionaryModel} from "../../../models/interfaces/dictionary-model";
import {NotificationModel} from "../../../models/interfaces/notification-model";
import {NewsModel} from "../../../models/interfaces/news-model";
import {CalendarEventModel} from "../../../models/interfaces/events-model";

export const initAuthUserData = createAction('[User] Init Auth User Data', props<{ user: UserModel }>());

export const initUser = createAction('[User] Init User', props<{ token: string }>());

export const setLoading = createAction('[User] Set Loading', props<{ isLoading: boolean }>());

export const setLoggedOut = createAction('[User] Set Logged Out');

export const refreshUser = createAction('[User] Refresh User');

export const updateUser = createAction('[User] Update User', props<{ user: UserModel }>());

export const setDictionary = createAction('[User] Set Dictionary', props<{ dictionary: DictionaryModel }>());

export const getAnnouncement = createAction('[User] Get Announcement');

export const setNotification = createAction('[User] Set Notification', props<{ notification: NotificationModel }>());

export const removeNotification = createAction('[User] Remove Notification', props<{ notification: NotificationModel }>());

export const initNews = createAction('[User] Init News');

export const setNews = createAction('[User] Set News', props<{ newsData: NewsModel[] }>());

export const initSidebarCalendarEvents = createAction('[User] Init Sidebar Calendar Events');

export const loadSidebarCalendarEventsDataChunk = createAction('[User] Load Sidebar Calendar Events Data Chunk', props<{ dateFrom: Date, dateTo: Date }>());

export const addSidebarCalendarEvent = createAction('[User] Add Sidebar Calendar Event to Store', props<{ eventsData: CalendarEventModel[] }>());
