import {userFeatureKey, userReducer, UserState} from "./user.reducer";
import {ActionReducerMap} from "@ngrx/store";

export interface AppState {
    [userFeatureKey]: UserState,
}

export const reducers: ActionReducerMap<AppState> = {
    [userFeatureKey]: userReducer,
}
