import {createReducer, on} from "@ngrx/store";
import {UserActions} from "../actions";
import {UserModel} from "../../../models/interfaces/user-model";
import {DictionaryModel} from "../../../models/interfaces/dictionary-model";
import {NotificationModel} from "../../../models/interfaces/notification-model";
import {NewsModel} from "../../../models/interfaces/news-model";
import {CalendarEventModel} from "../../../models/interfaces/events-model";

export const userFeatureKey = 'user';

export interface UserState {
    user: UserModel | null;
    isLoading: boolean;
    isLoggedOut: boolean;
    dictionary: DictionaryModel | null;
    notifications: NotificationModel[];
    newsList: NewsModel[] | null;
    sidebarCalendarEventsList: CalendarEventModel[] | null;
}

export const initialState: UserState = {
    user: null,
    isLoading: false,
    isLoggedOut: false,
    dictionary: null,
    notifications: [],
    newsList: null,
    sidebarCalendarEventsList: null,
}

export const userReducer = createReducer(
    initialState,
    on(UserActions.initAuthUserData, (state, {user}) => ({
        ...state,
        user,
        isLoggedOut: false,
    })),
    on(UserActions.updateUser, (state, {user}) => ({
        ...state,
        user,
    })),
    on(UserActions.setLoggedOut, () => ({
        ...initialState,
        isLoggedOut: true,
    })),
    on(UserActions.setLoading, (state, {isLoading}) => ({
        ...state,
        isLoading: isLoading,
    })),
    on(UserActions.setDictionary, (state, {dictionary}) => ({
        ...state,
        dictionary,
    })),
    on(UserActions.setNotification, (state, {notification}) => ({
        ...state,
        notifications: [notification, ...state.notifications],
    })),
    on(UserActions.removeNotification, (state, {notification}) => ({
        ...state,
        notifications: state.notifications.filter(n => n.getId() !== notification.getId()),
    })),
    on(UserActions.setNews, (state, { newsData }) => ({
        ...state,
        newsList: [ ...newsData ],
    })),
    on(UserActions.addSidebarCalendarEvent, (state, { eventsData }) => ({
        ...state,
        sidebarCalendarEventsList: [
            ...(state.sidebarCalendarEventsList ?? []),
            ...eventsData.filter(event =>
            !(state.sidebarCalendarEventsList ?? []).some(existingEvent =>
                existingEvent.dateFrom === event.dateFrom &&
                existingEvent.dateTo! === event.dateTo! &&
                existingEvent.actionType === event.actionType
            )
            )
        ]
    })),
)
